/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/feather/style.css";

@import '~node_modules/@swimlane/ngx-datatable/index.css';
@import '~node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~node_modules/@swimlane/ngx-datatable/assets/icons.css';

@import './scss/style';


.navbar-brand img {
  height: 40px !important;
}
/*
.page-header {
  @extend .d-flex, .flex-wrap, .align-items-center, .justify-content-between, .grid-margin;

  > * {
    @extend .d-flex, .align-items-center, .flex-wrap, .text-nowrap;
  }

  .btn:not(:last-child) {
    @extend .mr-3;
  }
}

.main-wrapper .page-wrapper .page-content {
  margin-top: 20px; // Override top margin when top-nav is disabled
}
*/
/*.card-header {
  border-radius: 20px 20px 0 0 !important;
  color: white;
  font-size: 120%;
  font-weight: bold;
  background-color: theme-color(primary);
  text-align: center;
}

.table-hover-effects datatable-body-row {
  cursor: pointer;

  &:hover {
    background: #d1e2f3 !important;
  }
}

.card {
  .card-header {
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0;
  }
}

.datatable-scroll-body {
  .datatable-body {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden; // TODO: Check compatibility and that nothing is hidden in all browsers
  }
}

.card-datatable-sticky-header {
  .card-header {
    border-bottom: 0;
    min-height: 40px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }


  .ngx-datatable.bootstrap {
    .datatable-header {
      background-color: theme-color(primary);
      color: white;

      .datatable-header-cell {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: none;
      }
    }

    .datatable-row-wrapper:first-child .datatable-body-row {
      border-top: none;
    }
  }


  .datatable-icon-down,
  .datatable-icon-up,
  .datatable-icon-sort-unset {
    width: 16px;
    height: 14px;
  }

  //.datatable-icon-down {
  //  background-image: url('assets/images/datatable/order-down-white.svg');
  //}
  //
  //.datatable-icon-up {
  //  background-image: url('assets/images/datatable/order-up-white.svg');
  //}
  //
  //.datatable-icon-sort-unset {
  //  background-image: url('assets/images/datatable/order-unordered-white.svg');
  //}

}

.datatable-header-cell-label {
  font-weight: bold;
}
*/
.ngx-datatable.bootstrap {
  font-size: $font-size-base;
}


/*
.badge {
  padding: 6px 12px 5px;
  border-radius: 15px;
  font-size: 85%;
}


.datatable-icon-down,
.datatable-icon-up,
.datatable-icon-sort-unset {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 12px;
  margin-left: 5px;

  &::before {
    display: none;
  }
}

//.datatable-icon-down {
//  background-image: url('assets/images/datatable/order-down.svg');
//}
//
//.datatable-icon-up {
//  background-image: url('assets/images/datatable/order-up.svg');
//}
//
//.datatable-icon-sort-unset {
//  background-image: url('assets/images/datatable/order-unordered.svg');
//}
*/

/**
 Forms
 */

.invalid-feedback {
  display: none;
  font-size: small;
}


.form-control.ng-invalid.ng-touched ~ .invalid-feedback {
  display: block;
}

.form-component.ng-invalid.ng-touched~.invalid-feedback {
  display: block;
}


input, select, textarea {

  &.ng-invalid.ng-touched {
    border: 1px solid red !important;

    & + label {
      color: red;
    }
  }
}

ngb-timepicker {
  &.ng-invalid.ng-touched input {
    border: 1px solid red !important;
  }
}


.form-control.disabled {
  background-color: $input-disabled-bg;
  opacity: 1;
}
